<template>
  <div>
    <h4>{{ $t('billing.events') }}
      <a href="#" @click.prevent="selectEvents">
        <small>{{ typeof selected !== 'undefined' && selected.length ? $t('result.remove_all'):$t('result.select_all') }}</small>
      </a>
    </h4>

    <div class="sources-columns users-columns  border-checkbox-section">
      <div
          v-for="(event, index) in events"
          :key="index" class="border-checkbox-group border-checkbox-group-primary">
        <input type="checkbox" class="border-checkbox" :id="`event_${index}`" v-model="selected"
               :value="event">
        <label :for="`event_${index}`" class="border-checkbox-label">
          {{$t(`billing.${event}`)}}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "eventsList",
  props: ['events'],
  data() {
    return {
      selected: [],
    }
  },
  methods:{
    selectEvents() {
      if(typeof this.selected !== 'undefined' && this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.events.map(event => event);
      }
    },
  },
  watch:{
    selected(){
      this.$emit("setEvents", this.selected);
    },
  },
  mounted() {
    this.selected = this.events.map(event => event)
  }
}
</script>

<style scoped>

</style>