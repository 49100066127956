<template>
  <div class="billingTable">

    <h4>{{ $t('group_form.users') }}
      <a href="#" @click.prevent="selectUsers">
        <small>{{ typeof selected !== 'undefined' && selected.length ? $t('group_form.select_nothing'):$t('group_form.select_all') }}</small>
      </a>
    </h4>
    <div v-if="usersLoading">
      <b-skeleton width="30%" :animated="true"></b-skeleton>

      <b-skeleton width="40%" :animated="true"></b-skeleton>

      <b-skeleton width="80%" :animated="true"></b-skeleton>

      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-else class="sources-columns users-columns m-b-15 border-checkbox-section">
      <div
          v-for="user in usersList"
          :key="user.id" class="border-checkbox-group border-checkbox-group-primary">
        <input type="checkbox" class="border-checkbox" :id="`user_${user.id}`" v-model="selected"
               :value="user.id">
        <label :for="`user_${user.id}`" class="border-checkbox-label">
          {{ user.login }}
        </label>
      </div>
    </div>

<!--    <b-table-->
<!--        style="max-height: 300px"-->
<!--        head-variant="light"-->
<!--        :no-border-collapse="noCollapse"-->
<!--        :items="usersList"-->
<!--        :fields="fields"-->
<!--        :select-mode="selectMode"-->
<!--        responsive-->
<!--        :busy="usersLoading"-->
<!--        :small="small"-->
<!--        ref="selectableTable"-->
<!--        selectable-->
<!--        @row-selected="onRowSelected"-->
<!--    >-->



<!--      <template #table-busy>-->
<!--        <div class="text-center text-primary my-2">-->
<!--          <b-spinner class="align-middle"></b-spinner>-->
<!--        </div>-->
<!--      </template>-->
<!--      &lt;!&ndash; Example scoped slot for select state illustrative purposes &ndash;&gt;-->
<!--      <template #cell(selected)="{ rowSelected }">-->
<!--        <template v-if="rowSelected">-->
<!--          <span aria-hidden="true">&check;</span>-->
<!--          <span class="sr-only">Selected</span>-->
<!--        </template>-->
<!--        <template v-else>-->
<!--          <span aria-hidden="true">&nbsp;</span>-->
<!--          <span class="sr-only">Not selected</span>-->
<!--        </template>-->
<!--      </template>-->
<!--      <template #cell(created_at)="row">-->
<!--        {{row.value | moment('DD.MM.YYYY')}}-->
<!--      </template>-->
<!--    </b-table>-->
<!--    <p style="display: flex; justify-content: flex-end">-->
<!--      <b-button size="sm" @click="clearSelected">{{$t('group_form.select_nothing')}}</b-button>-->
<!--      <b-button size="sm" style="margin-left: 1rem" @click="selectAllRows">{{$t('group_form.select_all')}}</b-button>-->
<!--    </p>-->
  </div>
</template>

<script>
export default {
  props:['usersList','usersLoading'],
  name: "usersList",
  data() {
    return {
      noCollapse: false,
      selectMode: 'multi',
      selected: [],
      small: true,
    }
  },
  computed:{
    fields(){
      return [
        {
          key: 'selected',
          label: 'Вибрано',
          sortable: false,
        },
        {
          key: 'login',
          label: 'Логин',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Дата создания',
          sortable: true,
        },
        {
          key: 'first_name',
          label: this.$t('user.first_name'),
          sortable: true,
        },
        {
          key: 'last_name',
          label: this.$t('user.last_name'),
          sortable: true,
        }
      ]
    },
  },
  watch:{
    selected(){
      this.$emit("setUsers", this.selected);
    },
  },

  methods:{
    selectUsers() {
      if(typeof this.selected !== 'undefined' && this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.usersList.map(user => user.id);
      }
    },
    unselectAll() {
      this.selected = [];
    },

    // onRowSelected(items) {
    //   this.selected = items
    // },
    // selectAllRows() {
    //   this.$refs.selectableTable.selectAllRows()
    // },
    // clearSelected() {
    //   this.$refs.selectableTable.clearSelected()
    // },
  },
}
</script>

<style>
 .thead-light{
  background-color: #f8f9fa;
}
</style>

<style lang="scss">
h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}
h3 {
  font-weight: 700;
  margin-bottom: 10px;
}
.sources-columns {
  column-count: 2;
  @media (max-width: 992px) {
    column-count: 1;
  }
}
.sources-columns {
  position: relative;

  .border-checkbox-section .border-checkbox-group .border-checkbox-label{
    margin-bottom: 10px;
    height: 60px;
  }
  .user-link {
    display: inline-block;
  }
  .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
    width: 100%;
    .check {
      margin-right: calc(0.75em - 1px);
    }
    .control-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
      position: relative;
      &:hover {
        background-color: #fff;
        overflow: visible;
        text-overflow: initial;
        text-decoration: underline;
        z-index: 99;
        padding-right: 20px;
      }
    }
  }
  @media (max-width: 992px) {
    &.users-columns {
      .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
        width: calc(100% - 35px);
      }
    }
  }
  .icon {
    svg {
      width: 16px;
    }
  }
}
@media (min-width: 993px) {
  .border-checkbox-group.border-checkbox-group-primary {
    display: block;
  }
}

</style>